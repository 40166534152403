<template>

  <section class="blank-page">
      <pageLoader :loadingText="'En cours de chargement'"/>
      <div class="content-wrapper">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                Ceci est un test
                <b-form-group>
                  <b-form-input placeholder="Entrer le texte"></b-form-input>
                </b-form-group>
                <b-form-group>
                  <b-form-input placeholder="Entrer le texte"></b-form-input>
                </b-form-group>
                <b-form-group>
                  <b-form-input placeholder="Entrer le texte"></b-form-input>
                </b-form-group>
                <b-form-group>
                  <b-form-input placeholder="Entrer le texte"></b-form-input>
                </b-form-group>
                <b-form-group>
                  <b-form-input placeholder="Entrer le texte"></b-form-input>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
      </div>
  </section>

</template>

<script>
import pageLoader from './../../components/pageLoader'
export default {
  name: 'essai',
  components: {
      pageLoader
  },
  data () {
      return {
          link: 'essai'
      }
  }
}
</script>